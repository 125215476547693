import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './SlickSlider.css'


const NextArrow = (props) => {
    const { className, style, onClick } = props;

    return (

        <div
            className={className}
            style={{ ...style, display: "block", top: "80px" ,background: "#FFFFFF",opacity: "0.9" , padding: "20px" , boxShadow : "0px 3px 6px #00000029" ,borderRadius: '50%'   }}
            onClick={onClick}
        />

    );
}

const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", zIndex : "1" , top: "80px" , background: "#FFFFFF",opacity: "0.9" , padding: "20px" , boxShadow : "0px 3px 6px #00000029" ,borderRadius: '50%' }}
            onClick={onClick}
        />
    );
}
var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    variableWidth: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                // dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                // initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

export const SlickSlider = ({ children }) => {
    return (
    
            <Slider className='w-[90vw]' {...settings}>
                    {children}
            </Slider>
      
    )
}
export const SlickSliders = ({ children }) => {
    return (
    
            <Slider className='w-[60vw]' {...settings}>
                    {children}
            </Slider>
      
    )
}