import React, { useState , useEffect } from 'react';
import { Loader } from './Loader';

const AgeVerification = () => {
    const [onVerify, setOnVerify] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const ageVerified = localStorage.getItem('ageVerified');
        setOnVerify(ageVerified ? ageVerified : false)
        setLoading(true)
    }, [])

    const handleNotVerifyAge = (event) => {
        event.preventDefault();
        setOnVerify(false);
        setShowContent(true);
    };
    const handleVerifyAge = (event) => {
        event.preventDefault();
        setOnVerify(true);
        localStorage.setItem('ageVerified' , true)
    };

  return (
      <>
          {loading ? 
              <>
                  {!onVerify ?
                            <div className="modal fixed z-50 left-0 top-0 flex h-full w-full items-center justify-center" >
                                <div className="modal-overlay absolute h-full w-full bg-gray-900 opacity-50"></div>
                                <div className="modal-container z-50 mx-auto flex items-center justify-center h-2/6 md:h-3/6 overflow-y-auto rounded bg-white shadow-lg w-9/12 md:max-w-2xl ">
                                {!showContent ? 
                                        <div className="modal-content px-6 py-4 text-left">
                                            <div className="flex items-center justify-center text-center pb-3">
                                                <p className="text-lg md:text-5xl font-bold">Are your over the Age of 18?</p>
                                            </div>
                                            <div className="md:flex text-center flex-wrap space-y-2  md:justify-center  pt-2  md:gap-4">
                                                <button onClick={handleNotVerifyAge} className="modal-action-button bg-orange-800 text-white font-normal md:font-bold py-2 px-4 md:py-5 md:px-6 rounded-full" onclick="closeModal()">No, I'm not over 18</button>
                                                <button onClick={handleVerifyAge} className="modal-action-button bg-myBg text-white font-normal md:font-bold py-2 px-4 md:py-5 md:px-6 rounded-full ml-2">Yes, I'm over 18</button>
                                            </div>
                                        </div>
                                    :
                                        <div className="modal-content px-6 py-4 text-left">
                                            <div className="flex items-center justify-center text-center pb-3">
                                                <p className="text-xl font-bold">Sorry, you are not allowed to access the website content due to age restrictions :(</p>
                                            </div>
                                        </div>
                                    }
                                    
                                
                                </div>
                            </div>
                    : null}
                </>
        : (< Loader />)}
          
      </>
  );
};

export default AgeVerification;
