import React from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM directly (not react-dom/client)
import './index.css';
import { App } from './App'; 
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import * as serviceWorkerRegister from './serviceWorkerRegistration';

const options = {
  position: positions.MIDDLE,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
};

ReactDOM.render( // Use ReactDOM.render() for React 17
  <React.StrictMode>
    <BrowserRouter>
      <AlertProvider template={AlertTemplate} {...options}>
        <App />
      </AlertProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root") 
);

serviceWorkerRegister.register();

reportWebVitals();
